<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { BCard, BButton, BModal, BFormFile, BProgress, BFormCheckbox } from 'bootstrap-vue'
export default {
    components: {
        BCard, BButton, BModal, BFormFile, BProgress, BFormCheckbox
    },
    data() {
        return {
            pageArrayStock: [], // for Frontend Search
            pageArray: [],

            editModal: false,
            editObject: {
                gameStatus: false,
                gameWork: false,
                versionApkUrl: '',
                versionCount: '',
                versionIpaUrl: '',
                versionName: '',
            },

            addModal: false,
            addObject: {
                gameStatus: false,
                gameWork: false,
                versionApkUrl: '',
                versionCount: '',
                versionIpaUrl: '',
                versionName: '',
            },

            filterName: '',
            filterCount: '',

            itemId : '',
            editVerionCount: 0,
            disabledCount: false
        }
    },
    async created() {
        await this.mountedData()
    },
    methods: {
        async mountedData() {

            // Clear Variables..
            this.addModal = false
            this.filterTitle = ''
            this.filterTitle = ''

            // Get List
            await this.$http.get('features/version/list')
                .then(response => {
                    this.pageArrayStock = this.pageArray = response.data
                }).catch(() => { alert('ERROR! PLEASE CONTACT TO DEVELOPER') })
        },
        async tableInteractive(item, index, type) {
            if (type === 'edit') {
                this.editVerionCount = item.versionCount
                this.editObject = structuredClone(item)
                this.editModal = true
            } else if ('delete') {
                this.itemId = item.versionId
            }else {
                this.editObject = item
            }
        },
        async saveData(type, item) {
            if (type === 'create') {

                await this.$http({
                    method: 'POST',
                    url: '/features/version/create',
                    data: this.addObject,
                    headers: {
                        "Content-Type": "application/json;charset=utf-8"
                    },
                }).then(() => {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Success, Please Wait..',
                            icon: 'CheckIcon',
                            variant: 'success',
                        },
                    })
                    this.mountedData()
                }).catch(error => {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'ERROR',
                            icon: 'XIcon',
                            variant: 'danger',
                        },
                    })
                })
            }
            if (type === 'update') {
                await this.$http({
                    url: '/features/version/update',
                    method: 'PUT',
                    data: this.editObject,
                    headers: {
                        "Content-Type": "application/json;charset=utf-8"
                    }
                }).then(() => {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Success, Please Wait..',
                            icon: 'CheckIcon',
                            variant: 'success',
                        },
                    })
                    this.mountedData()
                }).catch(error => {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'ERROR',
                            icon: 'XIcon',
                            variant: 'danger',
                        },
                    })
                })
            }
            if (type === 'delete') {
                await this.$http({
                    url: 'features/version/delete',
                    method: 'DELETE',
                    data: {
                        versionId: this.itemId
                    },
                    headers: {
                        "Content-Type": "application/json;charset=utf-8"
                    }
                }).then(() => {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Success, Please Wait..',
                            icon: 'CheckIcon',
                            variant: 'success',
                        },
                    })
                    this.mountedData()
                }).catch(error => {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'ERROR',
                            icon: 'XIcon',
                            variant: 'danger',
                        },
                    })
                })
            }
        },
        async filterNow() {
            this.pageArray = this.pageArrayStock.filter(x => x.noticeTitle.toString().toLocaleLowerCase().includes(this.filterTitle.toLocaleLowerCase()) && x.noticeDescription.toString().toLocaleLowerCase().includes(this.filterDescription.toLocaleLowerCase()))
        },
        openMedia(mediaUrl) {
            this.viewMedia = mediaUrl
            this.mediaModal = true
        },
        imagePreview(event) {
            const fileReader = new FileReader()
            fileReader.onload = () => {
                this.imageView = fileReader.result
            }
            fileReader.readAsDataURL(event.target.files[0])
            this.editObject.noticeImage = event.target.files[0]
        },
        deleteImage() {
            this.editObject.noticeImage = ''
        }
    },
    watch: {
        filterTitle: {
            handler() {
                this.filterNow()
            }
        },
        filterDescription: {
            handler() {
                this.filterNow()
            }
        },
        editObject: {
            handler() {
                if (this.editObject.versionCount < this.editVerionCount) {
                    this.disabledCount = true
                } else {
                    this.disabledCount = false
                }
            },
            deep: true,
        }
    }
}
</script>
            
    <template>
    <section class="pb-2">
        <div class="tb-card tb-flex-vertical">
            <a class="tb-page-title">Registered Users ({{ pageArrayStock.length }})</a>
            <button class="tb-button bg-primary ml-auto" @click="addModal = true">
                <a class="text-white">Create Notice</a>
            </button>
        </div>
        <div class="tb-card mt-2">
            <table class="tb-table">
                <thead>
                    <tr>
                        <th style="width: 5%"><input type="text" class="form-control" placeholder="#" disabled readonly>
                        </th>
                        <th style="width: 20%"><input type="text" class="form-control" placeholder="Version Name"
                                v-model="filterName"></th>
                        <th style="width: 20%"><input type="text" class="form-control" placeholder="Version Count"
                                v-model="filterCount"></th>
                        <th style="width: 20%"><input type="text" class="form-control" placeholder="Game Status"
                                disabled readonly></th>
                        <th style="width: 20%"><input type="text" class="form-control" placeholder="Game work" disabled
                                readonly></th>
                        <th style="width: 15%"><input type="text" class="form-control" placeholder="Options" disabled
                                readonly></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in pageArray">
                        <td><a>{{ index + 1 }}</a></td>
                        <td><a>{{ item.versionName }}</a></td>
                        <td><a>{{ item.versionCount }}</a></td>
                        <td><a>{{ item.gameStatus ? 'Active' : 'Passive' }}</a></td>
                        <td><a>{{ item.gameStatus ? 'Active' : 'Passive' }}</a></td>
                        <td>
                            <button class="tb-button bg-primary mx-1" @click="tableInteractive(item, index, 'edit')">
                                <span class="text-white">Edit</span>
                            </button>
                            <button class="tb-button bg-danger mx-1"
                                @click="$bvModal.show('bv-modal-version'), tableInteractive(item, index, 'delete')">
                                <span class="text-white">Delete</span>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <!-- Edit Modal -->
        <b-modal v-model="editModal" hide-header hide-footer centered size="lg">
            <div class="row py-1">
                <div class="col-12 text-center">
                    <a class="tb-page-title">Edit Version</a>
                    <hr>
                </div>
                <div class="col-12">
                    <label>Version Name</label>
                    <input type="text" class="form-control" v-model="editObject.versionName">
                </div>
                <div class="col-12">
                    <label>Version Count</label>
                    <input type="number" class="form-control" v-model.number="editObject.versionCount">
                </div>
                <div class="col-12">
                    <label>Version APK Url</label>
                    <input type="text" class="form-control" v-model="editObject.versionApkUrl">
                </div>
                <div class="col-12">
                    <label>Version IPA Url</label>
                    <input type="text" class="form-control" v-model="editObject.versionIpaUrl">
                </div>
                <div class="col-12">
                    <div class="col-2 mt-2">
                        <label>Game Status</label>
                        <b-form-checkbox v-model="editObject.gameStatus" switch />
                    </div>
                    <div class="col-2 mt-2">
                        <label>Game Work</label>
                        <b-form-checkbox v-model="editObject.gameWork" switch />
                    </div>
                </div>
                <div class="col-12 text-center">
                    <b-button variant="outline-primary" :disabled="disabledCount" class="mt-1"
                        @click="editModal = false, saveData('update')">Update
                        & Close
                    </b-button>
                </div>
            </div>
        </b-modal>
        <!-- Edit Modal -->
        <b-modal v-model="addModal" hide-header hide-footer centered size="lg">
            <div class="row py-1">
                <div class="col-12 text-center">
                    <a class="tb-page-title">Edit Version</a>
                    <hr>
                </div>
                <div class="col-12">
                    <label>Version Name</label>
                    <input type="text" class="form-control" v-model="addObject.versionName">
                </div>
                <div class="col-12">
                    <label>Version Count</label>
                    <input type="number" class="form-control" v-model="addObject.versionCount">
                </div>
                <div class="col-12">
                    <label>Version APK Url</label>
                    <input type="text" class="form-control" v-model="addObject.versionApkUrl">
                </div>
                <div class="col-12">
                    <label>Version IPA Url</label>
                    <input type="text" class="form-control" v-model="addObject.versionIpaUrl">
                </div>
                <div class="col-12">
                    <div class="col-2 mt-2">
                        <label>Game Status</label>
                        <b-form-checkbox v-model="addObject.gameStatus" switch />
                    </div>
                    <div class="col-2 mt-2">
                        <label>Game Work</label>
                        <b-form-checkbox v-model="addObject.gameWork" switch />
                    </div>
                </div>
                <div class="col-12 text-center">
                    <b-button variant="outline-primary" class="mt-1" @click="addModal = false, saveData('create')">
                        Create
                        & Close
                    </b-button>
                </div>
            </div>
        </b-modal>
        <!-- Media Modal -->

        <b-modal centered id="bv-modal-version" hide-header hide-footer>
            <h3 class="text-center mt-2">
                {{ editObject.noticeTitle }}
            </h3>
            <div class="d-block text-center mt-2">
                Are you sure you want to delete this Version ?
            </div>
            <div class="col-12 d-flex">
                <b-button class="mt-3 btn-danger mx-1" block
                    @click="$bvModal.hide('bv-modal-version'), saveData('delete')">Delete
                </b-button>
                <b-button class="mt-3 mx-1" block @click="$bvModal.hide('bv-modal-version')">Cancel
                </b-button>
            </div>
        </b-modal>

    </section>
</template>